<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <b-card header-tag="header" footer-tag="footer">
          <b-card-text>
            <div>
              <b-table
                striped
                hover
                :items="items"
                :fields="fields"
                :per-page="50"
                :current-page="currentPage"
              ></b-table>
            </div>
          </b-card-text>
          <template v-slot:footer>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="50"
              aria-controls="my-table"
            ></b-pagination>
          </template>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  data() {
    return {
      currentPage: 1,
      items: [],
      fields: [
        {
          key: "name",
          sortable: true,
          label: "Nombre"
        }
      ]
    };
  },
  computed: {
    rows() {
      return this.items.length;
    }
  },
  methods: {},
  created() {
    ApiService.get("api/subproject", "").then((response) => {
      this.items = response.data;
    });
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Mantenimientos" },
      { title: "Partidas" }
    ]);
  }
};
</script>
